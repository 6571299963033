import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// core components
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Grid from "@material-ui/core/Grid";
import SEO from "../components/SEO/SEO.jsx";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import HeaderLinks from "../components/Header/HeaderLinks.jsx";

import Intro from "../pages-sections/WatermainRes/Intro.jsx";

import HelpWith from "../pages-sections/WatermainRes/HelpWith.jsx";

import Contact from "../pages-sections/WatermainRes/Contact.jsx";

import {
  title,
  container,
  whiteColor,
  main,
  mainRaised,
} from "../jss/nextjs-material-kit-pro.js";
// Sections for this page
const useStyles = makeStyles({
  container: {
    ...container,
    zIndex: "10",
    color: "#FFFFFF",
    position: "relative",
  },

  mainRaised: {
    ...main,
    ...mainRaised,
  },
  parallax: {
    height: "70vh",
    "@media screen and (max-width:500px)": {
      height: "60vh",
    },
  },
  title: {
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontWeight: "500",
    fontSize: "4rem",
    textAlign: "center",
    fontStyle: "normal",
    fontFamily: "poppins",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      fontSize: "34px",
      marginTop: "120px",
    },
  },
  title3: {
    ...title,
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "poppins",
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontSize: "1.5rem",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
  },
  call: {
    fontSize: "2rem",
    textDecoration: "underline",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
    "&:hover": {
      color: "#0099CC",
      textDecoration: "underline",
    },
  },
  title2: {
    ...title,
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "poppins",
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontSize: "2rem",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
  },
  container2: {
    position: "absolute",
    width: "auto",
    height: "auto",
    top: "20%",
    left: "20%",
    textAlign: "center",
    marginTop: "-25px",
    "@media only screen and (min-width: 768px) and (max-height: 1024px)": {
      width: "auto",
      height: "auto",
      left: "10%",
      position: "absolute",
    },
    "@media only screen and (min-device-width: 1570px) and (max-height: 1570px)": {
      width: "auto",
      height: "auto",
      left: "30%",
      position: "absolute",
    },
    "@media only screen and (min-device-width: 1366px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)": {
      width: "auto",
      height: "auto",
      left: "23%",
      position: "absolute",
    },
    "@media only screen and (max-width: 500px)": {
      width: "auto",
      top: "5%",
      height: "auto",
      left: "10%",
      position: "absolute",
    },
  },
  blackOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
    paddingTop: "20px",
    color: "white",
  },
});

const getImages = graphql`
  {
    file(relativePath: { eq: "landingImage/watermain-replacement.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default function Home(props) {
  const classes = useStyles();
  const data = useStaticQuery(getImages);
  return (
    <div>
      <GatsbySeo
        title="Watermain Replacement | Toronto | GoPro Plumbing"
        description="Licensed plumbers in Toronto providing watermain replacement for residential units. Older watermain lines will corrode and can impact your health."
        canonical="https://goproplumbing.ca/watermain-replacement/"
      />
      <Header
        color="dark"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />
      <SEO />

      <div filter="dark" className={classes.parallax}>
        <div className={classes.blackOverlay}>
          <Grid container justify="center">
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              className={classes.container2}
            >
              <h1 className={classes.title}>Water Main Replacement</h1>
              <h2 className={classes.title2}>Clean Water</h2>
              <p className={classes.title3}>OPEN 24/7, CALL NOW</p>
              <div align="center">
                <a href="tel:647-370-9868" className={classes.call}>
                  647-370-9868
                </a>
              </div>
            </Grid>

            <Img
              fluid={data.file.childImageSharp.fluid}
              fadeIn={false}
              alt="watermain-replacement"
              loading="eager"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "-1",
              }}
            />
          </Grid>
        </div>
      </div>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        id="main-panel"
      >
        <div className={classes.container}>
          <Intro />
        </div>
        <HelpWith />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
